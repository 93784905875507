








































import {Component, Prop, Vue} from 'vue-property-decorator'
import {RecoverPasswordByMailRequest} from '@/model/request/RecoverPasswordByMailRequest'
import {InputRecoverPasswordByMailRequestSchema} from '@/schema/request/RecoverPasswordByMailRequest/InputRecoverPasswordByMailRequestSchema'

@Component
export default class RecoverPasswordByMailView extends Vue {
  schema = new InputRecoverPasswordByMailRequestSchema()
  request = new RecoverPasswordByMailRequest()

  async submit() {
    await this.request.recoverPasswordByMail()
    this.$toast.success('system.success.recoverPasswordByMail')
    await this.$nav.push('/sign-in')
  }
}
